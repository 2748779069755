<template>
  <div>
    <div class="gridContainer">
      <div v-if="layoutData.length == 0">
        <div class="title is-4 has-text-centered">Wallet is Empty</div>
      </div>
      <div v-for="(item, id) in layoutData" :key="item.key" :class="{ gridItem: true, active: id === activeItem }">
        <div>
          <ffc :itemID="item.i" :cardid="id" :itemDisplayName="item.workflow_name" :itemWID="item.workflow_id"
            :itemState="item.current_state" :itemInvalidated="item.invalidated" :isOwner="item.is_owner" :sharedBy="item.shared_by"
            @selected="selectedItem"></ffc>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGridLayout from "vue-grid-layout";
import OverlayPanel from "primevue/overlaypanel";
import { globalFunction } from "../services/global.function";

export default {
  components: {
    ffc: () => import("../components/tokenCard.vue"),
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    OverlayPanel,
  },
  props: {
    alldata: Array,
    noOfColumns: {
      type: Number,
      required: true,
    },
    cellWith: {
      type: Number,
      required: true,
    },
    cellHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      delay: 700,
      clicks: 0,
      timer: null,
      txns: [],
      subFloderItem: [],
      currentFolder: "",
      isSignModalActive: false,
      isFilterEnable: false,
      activeItem: null,
      selectedtags: [],
      searchValue: "",
      filterVue: [
        {
          name: "Other",
          value: "pendingOnMe",
          color: "slider-yellow",
          defaultname: "Me",
        },
        {
          name: "Pending",
          value: "timeoutOnMe",
          color: "slider-orange",
          defaultname: "Urgent",
        },
      ],
    };
  },
  mounted: function () {
    this.$root.$on("search_bar_data", (data) => {
      console.log("search data");
      this.searchValue = data;
      this.selectedtags.push("searchKey");
    });
    this.$root.$on("search_bar_empty", () => {
      console.log("search empty");
      this.searchValue = "";
      const index = this.selectedtags.indexOf("searchKey");
      if (index > -1) {
        this.selectedtags.splice(index, 1);
      }
    });
  },
  computed: {
    actualWorkFlowID() {
      return this.$store.getters.getWorkFlowID;
    },
    layoutData() {
      let layoutdata = [];
      console.log("data for all", this.section, ":", this.alldata);
      this.alldata.forEach((item) => {
        layoutdata.push({
          w: this.cellWith,
          h: this.cellHeight,
          i: item.id,
          key: Math.floor(Math.random() * Date.now()).toString(16),
          workflow_id: item.workflow_id,
          current_state: item.current_state,
          workflow_name: item.workflow_name,
          invalidated: item.invalidated,
          is_owner: item.is_owner,
          shared_by: item.shared_by,
        });
      });
      if (this.selectedtags.length === 0) {
        return layoutdata; // Return the original array if no filters are selected
      } else {
        if (this.selectedtags.includes("searchKey")) {
          layoutdata = layoutdata.filter((item) => {
            console.log("data", item);
            const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;            
            if (this.searchValue.startsWith("wo-")) {
              return item.workflow_id.toLowerCase().includes(this.searchValue.toLowerCase());  
            } else if (regexExp.test(this.searchValue)) {
              return item.i.toLowerCase().includes(this.searchValue.toLowerCase());  
            }
            return item.workflow_name.toLowerCase().includes(this.searchValue.toLowerCase());
          });
        }
      }
      console.log("layoutData:", layoutdata);
      return layoutdata;
    },
  },
  methods: {
    selectedItem: async function (n) {
      this.activeItem = n;
      console.log("Selected Card", n);
    },
  },
  beforeDestroy() {
    this.$root.$off("item-clicked");
  },
};
</script>
<style lang="scss">
.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 20rem));
  padding: 10px;
  -moz-column-gap: 50px;
  column-gap: 30px;
  margin-top: 20px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  height: 100vh;
  height: calc(100vh - 132px);
}

.gridItem {
  // background-color: rgba(255, 255, 255, 0.8);
  // border: 1px solid rgba(0, 0, 0, 0.8);
  // padding: 20px;
  // font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.gridItem.active {
  border: 4px solid #76add8;
  border-radius: 7px;
}

.gridItem.active:hover {
  border: 4px solid #76add8;
  border-radius: 7px;
}

// .gridItem:hover {
// background-color: aliceblue;
// }
.listContainer {
  display: contents;
}

.listItem {
  // background-color: rgba(255, 255, 255, 0.8);
  //border: 1px solid rgba(0, 0, 0, 0.8);
  text-align: left;
}

.listItem:hover {
  background-color: aliceblue;
}
</style>
